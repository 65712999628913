export default [
  {
    isTitle: 'Tracking Number',
    index: 'trackingId'
  },
  {
    isExtra: 'Age',
    index: 'age',
    title: 'Age - '
  },
  {
    isIcon: 'Ranking',
    index: 'ranking'
  },
  {
    title: 'Diagnosis Date',
    index: 'dateOfMMDiagnosis'
  },
  {
    title: 'Prior Lines',
    index: 'numberOfPriorLinesOfMMTherapy'
  }
];
