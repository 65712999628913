import React from 'react';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { NavBar } from 'antd-mobile';
import PDFViewer from '@/components/PDFViewer';

const Viewer = ({ dispatch, protocols }) => {
  const ids = window.location.href.split('/');
  const id = ids[ids.length - 1];
  if (!id || !protocols) {
    return <div>no file found</div>;
  }
  return (
    <div>
      <NavBar
        leftContent="Back"
        onLeftClick={() => dispatch(goBack())}
        mode="dark"
      >
        {protocols.find(element => element.id === id).title}
      </NavBar>
      <PDFViewer url={protocols.find(element => element.id === id).file.url} />
    </div>
  );
};

export default connect(state => ({
  protocols: state.user.protocols
}))(Viewer);
