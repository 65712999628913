import React from 'react';
import { Link } from 'react-router-dom';
import config from '@utils/config';

const Page404 = () => {
  return (
    <div
      style={{
        textAlign: 'center',
        paddingTop: '4rem',
        background: '#fff',
        minHeight: '100vh'
      }}
    >
      <div style={{ fontSize: '4rem', fontWeight: 'bold' }}>404</div>
      <div>Not Found</div>
      <div> You may want to access</div>
      <ul style={{ display: 'inline-block', textAlign: 'left' }}>
        <li>
          <Link to={config.routes.waitlist}>Waitlist</Link>
        </li>
        <li>
          <Link to={config.routes.treatment}>Treatment</Link>
        </li>
        <li>
          <Link to={config.routes.followUp}>Follow up</Link>
        </li>
        <li>
          <Link to={config.routes.taguideline}>TA Guidelines</Link>
        </li>
        <li>
          <Link to={config.routes.slotCapability}>Slot Capability</Link>
        </li>
        <li>
          <Link to={config.routes.studyprotocol}>Study Protocols</Link>
        </li>
      </ul>
    </div>
  );
};

export default Page404;
