/**
 * @defination: customize tab filter and search
 * @props: filter -> array [{key: 'attributes', value: 'display name', options: [types of options to select]}]
 *                               no options will be input
 *                 sort -> array [{key: 'attribtues', value: 'display name'}] default will be the first element
 *                 handleSubmit -> function (sort->[keys], filter-> [keys])
 */
import React from 'react';
import { InputItem, Button, List } from 'antd-mobile';
import { createForm } from 'rc-form';
import BtnGroup from './BtnGroup';
import styles from './index.module.scss';

const MULTI_TYPE_SELECT = 'multiple';

const FilterSection = ({
  filterSec,
  getFieldProps,
  filterOptions,
  handleSelect
}) => {
  let value;

  const handleSelectOptions = e => {
    let newFilter = JSON.parse(JSON.stringify(filterOptions));
    if (e.value) {
      newFilter[e.key] = e.value;
    }
    handleSelect(newFilter);
  };

  if (filterSec.options) {
    value = (
      <BtnGroup
        selectedArray={filterOptions[filterSec.key] || ['']}
        options={filterSec.options}
        type={filterSec.type || MULTI_TYPE_SELECT}
        params={filterSec.key}
        handleClick={e => handleSelectOptions(e)}
      />
    );
  } else {
    value = (
      <List key={filterSec.key}>
        <InputItem
          {...getFieldProps(filterSec.key, {
            initialValue: filterOptions[filterSec.key] || ''
          })}
          key={filterSec.key}
          placeholder={`Please input ${filterSec.title}`}
        />
      </List>
    );
  }
  return (
    <div className={styles['filter-section']}>
      <p className={styles['filter-title']}>{filterSec.title}</p>
      <div
        className={filterSec.options ? styles['filter-section-items'] : null}
        key={filterSec.key}
      >
        {value}
      </div>
    </div>
  );
};

const FilterList = ({ filterMap, handleFilter, form, isOpen }) => {
  const [filter, setFilter] = React.useState(
    JSON.parse(sessionStorage.getItem('filter')) || {}
  );
  const { getFieldProps } = form;

  React.useEffect(() => {
    if (isOpen) {
      const filter_from_session = JSON.parse(sessionStorage.getItem('filter'));
      setFilter(filter_from_session || {});
    }
  }, [isOpen]);

  const onClickReset = () => {
    setFilter({});
    form.resetFields();
  };

  const onClickFilter = () => {
    let newFilter = JSON.parse(JSON.stringify(filter));
    let inputValue = form.getFieldsValue();
    let newInput = {};
    Object.keys(inputValue).forEach(key => {
      if (inputValue[key]) {
        newInput[key] = inputValue[key];
      }
    });
    Object.keys(newFilter).forEach(key => {
      if (!newInput[key]) {
        newInput[key] = newFilter[key];
      }
    });
    handleFilter(newInput);
  };

  const handleSelect = value => {
    setFilter(value);
  };

  let list = filterMap.map(ele => {
    return (
      <FilterSection
        filterSec={ele}
        key={ele.key}
        filterOptions={filter}
        handleSelect={handleSelect}
        getFieldProps={getFieldProps}
      />
    );
  });

  return (
    <div className={styles['filter-list']}>
      <div className={styles['filter-sections']}>{list}</div>
      <div className={styles['button-div']}>
        <Button
          type="ghost"
          size="small"
          inline
          onClick={() => onClickReset()}
          className={[styles['filter-btn'], 'am-button-borderfix']}
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          inline
          onClick={() => onClickFilter()}
        >
          Search
        </Button>
      </div>
    </div>
  );
};

export default createForm()(FilterList);
