/* eslint-disable no-undef */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import DetailList from '@components/DetailList';
import map from './map';
import request from '@utils/request';
import config from '@utils/config';
import { NavBar } from 'antd-mobile';
import { goBack } from 'connected-react-router';

const WaitListDetail = ({ dispatch, waitlist }) => {
  const [patient, setPatient] = React.useState(null);

  React.useEffect(() => {
    const ids = window.location.href.split('/');
    const id = ids[ids.length - 1];
    if (!waitlist) {
      request.get({ url: `${config.api.patients}/${id}` }).then(res => {
        setPatient(res);
      });
    } else {
      setPatient(waitlist.find(element => element.id === id));
    }
  }, [dispatch]);

  if (!patient) {
    return <div>no patient found</div>;
  }

  return (
    <Fragment>
      <NavBar
        leftContent="Back"
        onLeftClick={() => dispatch(goBack())}
        mode="dark"
      >
        Detail
      </NavBar>
      <DetailList title={patient.trackingId} map={map} data={patient} />
    </Fragment>
  );
};

export default connect(state => ({
  waitlist: state.patient.waitlist
}))(WaitListDetail);
