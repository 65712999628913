/**
 *   Cookie
 *  @reference: https://www.w3schools.com/js/js_cookies.asp
 */

/**
 * set cookie with key, value and expire days(not reqired)
 * @param {string} cname cookie key
 * @param {string} cvalue cookie value
 * @param {number} exdays cookie expire days
 */
const setCookie = (cname, cvalue, exdays = 7) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

/**
 * get cookie value by key
 * @param {string} cname cookie key
 */
const getCookie = cname => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const deleteCookie = name => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
};

/**
 * @param {*} key param name
 */
const getParamByName = key => {
  return new URL(window.location.href).searchParams.get(key);
};

const addDate = (date, days) => {
  if (days == undefined || days === '') {
    days = 1;
  }
  date = new Date(date);
  date.setDate(date.getDate() + days);
  var month = date.getMonth() + 1;
  var day = date.getDate();
  return `${getFormatDate(day)} ${Intl.DateTimeFormat('en-US', {
    month: 'short'
  }).format(date)} ${date.getFullYear()}`;
};

function getFormatDate(arg) {
  if (arg == undefined || arg == '') {
    return '';
  }

  var re = arg + '';
  if (re.length < 2) {
    re = '0' + re;
  }

  return re;
}

function getDate(date) {
  date = new Date(date);
  var day = date.getDate();
  return `${getFormatDate(day)} ${Intl.DateTimeFormat('en-US', {
    month: 'short'
  }).format(date)} ${date.getFullYear()}`;
}
export default {
  setCookie,
  getCookie,
  deleteCookie,
  getParamByName,
  addDate,
  getDate
};
