import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { LOGIN } from './actions';
import { Toast } from 'antd-mobile';

import config from '@utils/config';
import common from '@utils/common';
import { ConnectedRouter } from 'connected-react-router';

import Register from './containers/Register';
import Application from './containers/Application';

const REDIRECT_URI = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=
${process.env.REACT_APP_APP_ID}
&redirect_uri=${window.location.origin}
&response_type=code
&scope=snsapi_base&state=123#wechat_redirect`;

function App({ dispatch, user, history }) {
  const [isloading, setIsloading] = React.useState(true);
  React.useEffect(() => {
    const userSaved = JSON.parse(sessionStorage.getItem('user'));
    // user havent login
    if (!userSaved && !sessionStorage.getItem('jwt')) {
      const code = common.getParamByName('code');
      //if the code is empty, force redirect to get wechat auth code
      if (!code) {
        sessionStorage.setItem('des', window.location.pathname);
        window.location.href = REDIRECT_URI;
        return;
      }
      setIsloading(false);
      //otherwise login
      dispatch({
        type: LOGIN,
        payload: { value: { code } }
      });
      // user login but not approved
    } else if (
      userSaved &&
      !sessionStorage.getItem('jwt') &&
      !window.location.href.includes('/register')
    ) {
      window.location.href = '/register';
    } else if (!userSaved && sessionStorage.getItem('jwt')) {
      common.deleteCookie('jwt');
      window.location.href = window.location.href;
    }
  }, [dispatch]);

  if (
    !sessionStorage.getItem('jwt') &&
    isloading &&
    !window.location.href.includes('/register')
  ) {
    Toast.loading('Loading...', 0, 1);
    return <div />;
  }
  return (
    <ConnectedRouter history={history}>
      <Route path={config.routes.app} component={Application} />
      <Route exact path={config.routes.register} component={Register} />
    </ConnectedRouter>
  );
}

export default connect(state => ({
  user: state.user.user
}))(App);
