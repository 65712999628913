import common from '@utils/common';

export default [
  {
    title: 'Site ID',
    index: 'site.siteID'
  },
  {
    title: 'Site Name',
    index: 'site.siteName'
  },
  {
    title: 'Tracking Number',
    index: 'trackingId'
  },
  { title: 'Gender', index: 'gender' },
  {
    title: 'Ranking',
    index: 'ranking'
  },
  {
    title: 'Age(Years)',
    index: 'age'
  },
  {
    title: 'Date of MM Diagnosis',
    index: 'dateOfMMDiagnosis'
  },
  {
    title: 'Prior Lines of MM therapy',
    index: 'numberOfPriorLinesOfMMTherapy'
  },
  {
    title: 'Received Time',
    index: 'receivedTime',
    render: date => {
      return common.getDate(new Date(date));
    }
  },
  {
    title: 'PD status',
    index: 'PDStatus'
  },
  {
    title: 'Body weight (kg)',
    index: 'weight'
  },
  {
    title: 'Comment',
    index: 'comment'
  }
];
