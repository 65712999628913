import React from 'react';

const pendingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 42 42"
    className="am-icon am-icon-md"
  >
    <g fillRule="evenodd" stroke="transparent" strokeWidth="4">
      <path
        fill="#108ee9"
        d="M21 0C9.402 0 0 9.402 0 21c0 11.6 9.402 21 21 21s21-9.4 21-21C42 9.402 32.598 0 21 0z"
      />
    </g>
  </svg>
);

export default [
  {
    title: 'Screening',
    index: 'dateICF',
    doneIndex: 'doneICF'
  },
  {
    title: 'Apheresis',
    index: 'dateApheresis',
    doneIndex: 'doneApheresis'
  },
  {
    showIndex: 'secondApheresisNeeded',
    title: '2nd Apheresis',
    keepDate: true,
    index: 'dateOf2ndApheresis',
    hasIcon: pendingIcon()
  },
  {
    showIndex: 'bridgingTherapyNeeded',
    title: 'Bridging Start Date',
    index: 'dateBridgingTherapyStart',
    keepDate: true,
    hasIcon: pendingIcon()
  },
  {
    showIndex: 'bridgingTherapyNeeded',
    title: 'Bridging End Date',
    index: 'dateBridgingTherapyEnd',
    keepDate: true,
    hasIcon: pendingIcon()
  },
  {
    title: 'CAR-T release',
    index: 'dateCART',
    doneIndex: 'doneCART'
  },
  {
    title: 'Lymphodepletion Start',
    index: 'dateLymphodepletionStart',
    doneIndex: 'doneLymphodepletionStart'
  },
  {
    title: 'CAR-T Dose',
    index: 'dateCARTInfusion',
    doneIndex: 'doneCARTInfusion'
  }
];
