export default [
  {
    value: 'Ranking',
    label: 'Ranking',
    key: 'ranking'
  },
  {
    value: 'Descending Ranking',
    label: 'Descending Ranking',
    key: 'ranking:DESC'
  }
];
