import React from 'react';
import { List } from 'antd-mobile';
import styles from './index.module.scss';
import get from 'lodash.get';

const Item = List.Item;

const getItems = (map, data) => {
  const res = [];
  map.forEach(element => {
    if (element.render) {
      res.push(
        <Item
          wrap
          key={element.index}
          extra={element.render(get(data, element.index))}
        >
          {element.title}
        </Item>
      );
    } else {
      res.push(
        <Item wrap key={element.index} extra={get(data, element.index)}>
          {element.title}
        </Item>
      );
    }
  });
  return res;
};

const DetailList = ({ title, data, map }) => {
  return (
    <div className={styles.body}>
      <List renderHeader={() => title}>{getItems(map, data)}</List>
    </div>
  );
};

export default DetailList;
