import dateMap from './dateMap';
import common from '@utils/common';

export default [
  {
    isTitle: 'Tracking Number',
    index: ['subjectId', 'trackingId']
  },
  {
    isExtra: 'Age',
    index: 'statusFollowUp'
  },
  {
    title: 'CART-T Dose Date',
    index: 'dateCARTInfusion',
    render: date => {
      if (!date) {
        return null;
      }
      return common.getDate(new Date(date));
    }
  },
  {
    title: 'PD Date',
    require: false,
    index: 'datePDFollowUp',
    render: date => {
      if (!date) {
        return null;
      }
      return common.getDate(new Date(date));
    }
  },
  {
    title: 'Next Visit Date',
    index: 'dateCARTInfusion',
    render: (date, record) => {
      if (!date) {
        return null;
      }
      const d = dateMap(record.statusFollowUp);
      if (record.statusFollowUp === 'Prior to PD') {
        for (let i = 0; i < d.length; i++) {
          if (
            new Date(common.addDate(date, d[i] - 1)).valueOf() >
            new Date().valueOf()
          ) {
            return common.getDate(new Date(common.addDate(date, d[i] - 1)));
          }
        }
      } else if (record.statusFollowUp === 'Post PD') {
        for (let i = 0; i < d.length; i++) {
          if (
            new Date(
              common.addDate(record.datePDFollowUp, d[i] - 1)
            ).valueOf() > new Date().valueOf()
          ) {
            return common.getDate(
              new Date(common.addDate(record.datePDFollowUp, d[i] - 1))
            );
          }
        }
      } else {
        return 'not exist';
      }
    }
  }
];
