import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import user from './user.js';
import patient from './patient.js';

const reducer = history =>
  combineReducers({
    router: connectRouter(history),
    user,
    patient
  });

export default reducer;
