/**
 * @defination: customize tab filter and search
 * @props: filter -> array [{key: 'attributes', value: 'display name', options: [types of options to select]}]
 *                               no options will be input
 *                 sort -> array [{key: 'attribtues', value: 'display name'}] default will be the first element
 *                 handleSubmit -> function (sort->[keys], filter-> [keys])
 */
import React from 'react';
import { Menu } from 'antd-mobile';
import styles from './index.module.scss';

const SortMenu = ({ sortItem, onChange, sort }) => {
  const onSortChange = item => {
    onChange(item[0]);
  };

  return (
    <Menu
      className={styles['sort-menu']}
      data={sortItem}
      value={[sort]}
      level={1}
      onChange={onSortChange}
      height={'fit-content'}
    />
  );
};

export default SortMenu;
