import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { FETCH_PROTOCOL, VIEW_PROTOCOL } from '@/actions';
import { List, Toast, NavBar } from 'antd-mobile';
import { push } from 'connected-react-router';

const Item = List.Item;

const StudyProtocol = ({ dispatch, protocols }) => {
  React.useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch({
        type: FETCH_PROTOCOL,
        payload: { resolve, reject }
      });
    }).then(() => {
      Toast.hide();
    });
  }, [dispatch]);
  if (!protocols) {
    Toast.loading('Loading...', 0, 1);
    return null;
  }
  return (
    <Fragment>
      <NavBar mode="dark">Study Protocols</NavBar>
      <List
        renderHeader={() => (
          <div>
            Study protocols{' '}
            <span style={{ float: 'right' }}>{`${
              protocols.length
            } files`}</span>
          </div>
        )}
      >
        {protocols.map(element => {
          return (
            <Item
              arrow="horizontal"
              key={element.id}
              multipleLine
              onClick={() => {
                dispatch(push(`/app/studyprotocol/${element.id}`));
              }}
            >
              {element.title}
            </Item>
          );
        })}
      </List>
    </Fragment>
  );
};

export default connect(state => ({
  protocols: state.user.protocols
}))(StudyProtocol);
