/*
 * @defination: wrap axios for http request, resquest params objects will be encoded by qs
 * @reference: axios https: //github.com/axios/axios
 * @reference: qs https: //www.npmjs.com/package/qs
 */
import axios from 'axios';
import * as auth from './auth.js';
import qs from 'qs';

let CancelToken = axios.CancelToken;
let source = CancelToken.source();

const TIMEOUT = 5000;
const TOKEN_TYPE = 'Bearer';

const token = () => {
  if (!auth.getToken() || !auth.getToken().length) {
    return null;
  }
  return auth.getToken();
};

// const errorMsg = [
//   {
//     status: 401,
//     msg: '未授权，请登录'
//   },
//   {
//     status: 400,
//     msg: '请求错误'
//   },
//   {
//     status: 403,
//     msg: '拒绝访问'
//   },
//   {
//     status: 404,
//     msg: '找不到资源'
//   },
//   {
//     status: 408,
//     msg: '请求超时'
//   }
// ];

// create axios instance
const service = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL || ' ',
  timeout: TIMEOUT,
  mode: 'no-cors',
  // withCredentials: true,
  paramsSerializer: function(params) {
    return qs.stringify(params, {
      arrayFormat: 'repeat' // ex: a:[b,c] => a=b&a=c
    });
  },
  cancelToken: source.token
});

service.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';

if (token())
  service.defaults.headers.common['Authorization'] = `${TOKEN_TYPE} ${token()}`;

service.all = axios.all;

// request blocker
service.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    if (
      error.code === 'ECONNABORTED' &&
      error.message.indexOf('timeout') !== -1
    ) {
      console.log('Timeout: you can add your timeout operations here!');
    }
    return Promise.reject(error);
  }
);

// response blocker
service.interceptors.response.use(
  response => {
    const res = response.data;
    return res;
  },

  error => {
    //TODO: error manage
    return Promise.reject(error);
  }
);

export default service;
