import React from 'react';

const LoadMore = ({ title }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        padding: '1rem 0 2rem'
      }}
    >
      <div
        style={{
          margin: 'auto',
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
          width: '10rem'
        }}
      >
        <span
          style={{
            transform: 'translateY(50%)',
            backgroundColor: '#f5f5f9',
            padding: '0 0.3rem',
            display: 'inline-block',
            color: '#CCC'
          }}
        >
          {title}
        </span>
      </div>
    </div>
  );
};
export default LoadMore;
