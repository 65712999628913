import React from 'react';
import {
  WingBlank,
  InputItem,
  List,
  WhiteSpace,
  Picker,
  Button,
  Toast,
  Result,
  Icon
} from 'antd-mobile';
import { createForm } from 'rc-form';

import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { REGISTER, FETCH_SITE, SAVE } from '@/actions';
import common from '@utils/common';

const roles = [
  [
    {
      label: 'study center',
      value: 'study center'
    },
    {
      label: 'non study center',
      value: 'non study center'
    }
  ]
];

const Register = ({ form, dispatch, user }) => {
  const { getFieldProps, validateFields } = form;

  const [role, setRole] = React.useState(null);
  const [sitesArray, setSitesArray] = React.useState(null);

  React.useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    dispatch({ type: SAVE, payload: { key: 'user', value: user } });
    if (sessionStorage.getItem('jwt')) {
      dispatch(push('/app/waitlist'));
    }
  }, [dispatch, role]);

  const handleClick = () => {
    validateFields((error, values) => {
      if (!error) {
        let value = {};
        if (role && role[0] && role[0] === roles[0][0].value) {
          value = {
            ...values,
            id: user.id,
            site: values.site[0],
            type: values.type[0]
          };
        } else {
          value = {
            ...values,
            id: user.id,
            type: values.type[0]
          };
        }
        new Promise((resolve, reject) => {
          dispatch({
            type: REGISTER,
            payload: { values: value, resolve, reject }
          });
        }).then(() => {
          Toast.info('Submitted.');
        });
        return;
      }
      Toast.info('Please complete the form.');
    });
  };
  if (user && user.type && user.approved === null) {
    return (
      <div className={styles.body}>
        <Result
          style={{ marginTop: '10rem' }}
          img={<Icon type="check-circle" className={styles['success-img']} />}
          title="Await approval"
          message="Please contact admin to approve your request."
        />
      </div>
    );
  } else if (user && user.approved === false) {
    return (
      <div className={styles.body}>
        <Result
          style={{ marginTop: '10rem' }}
          img={<Icon type="cross-circle-o" className={styles['failed-img']} />}
          title="Rejected"
          message="Sorry, your request was rejected."
        />
        <Button
          type="ghost"
          size="small"
          style={{ margin: '4px' }}
          onClick={() => {
            const user = JSON.parse(sessionStorage.getItem('user'));
            delete user.type;
            delete user.approved;
            sessionStorage.setItem('user', JSON.stringify(user));
            dispatch({ type: SAVE, payload: { key: 'user', value: user } });
          }}
        >
          re-register
        </Button>
      </div>
    );
  }
  return (
    <div className={styles.body}>
      <WingBlank size="lg" style={{ marginTop: '10rem' }}>
        <div className={styles.logo} />
        <List
          renderHeader={() =>
            'Please provide your information to assess the app'
          }
        >
          <InputItem
            className={styles.inputItem}
            {...getFieldProps('firstName', { rules: [{ required: true }] })}
          >
            First name
          </InputItem>
          <InputItem
            className={styles.inputItem}
            {...getFieldProps('lastName', { rules: [{ required: true }] })}
          >
            Last name
          </InputItem>
          <Picker
            data={roles}
            value={role}
            title="User type"
            cascade={false}
            extra=" "
            {...getFieldProps('type', {
              onChange(v) {
                // if user choose study center and the sites are null
                if (v[0] === roles[0][0].value && !sitesArray) {
                  new Promise((resolve, reject) => {
                    dispatch({
                      type: FETCH_SITE,
                      payload: { resolve, reject }
                    });
                  }).then(res => {
                    const array = [];
                    array.push([]);
                    res.forEach(element => {
                      array[0].push({
                        label: (
                          <p>
                            {element.siteID}
                            <br />
                            {element.siteName}
                          </p>
                        ),
                        value: element.id
                      });
                    });
                    array[0] = array[0].reverse();
                    setSitesArray(array);
                  });
                }
                setRole(v);
              },
              rules: [{ required: true }]
            })}
          >
            <List.Item arrow="horizontal">User type</List.Item>
          </Picker>

          {role && role[0] === roles[0][0].value && sitesArray ? (
            <Picker
              indicatorStyle={{ height: '68px' }}
              itemStyle={{ height: '68px' }}
              data={sitesArray}
              title="Site"
              cascade={false}
              extra=" "
              {...getFieldProps('site', {
                onChange(v) {},
                rules: [{ required: true }]
              })}
            >
              <List.Item arrow="horizontal">Site</List.Item>
            </Picker>
          ) : null}

          {role && role[0] === roles[0][1].value ? (
            <InputItem
              className={styles.inputItem}
              {...getFieldProps('email', { rules: [{ required: true }] })}
            >
              {/* <Icon type="mail" /> */}
              Email
            </InputItem>
          ) : null}
        </List>

        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />

        <WingBlank>
          <Button type="primary" onClick={handleClick}>
            Register
          </Button>
        </WingBlank>
      </WingBlank>
    </div>
  );
};

export default withRouter(
  connect(state => ({
    user: state.user.user
  }))(createForm()(Register))
);
