import React from 'react';

import { Pagination } from 'antd-mobile';

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/
${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({ url }) => {
  const [pages, setPages] = React.useState(0);

  React.useEffect(() => {
    document
      .getElementById('viewport')
      .setAttribute(
        'content',
        'width=device-width, initial-scale=1, shrink-to-fit=no'
      );
    return () => {
      document
        .getElementById('viewport')
        .setAttribute(
          'content',
          'width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1.0, user-scalable=0'
        );
    };
  });

  const getPages = () => {
    const res = [];
    for (let i = 1; i <= pages; i++) {
      res.push(
        <div style={{ marginBottom: '1rem' }} key={i}>
          <Page pageNumber={i} width={document.body.clientWidth} key={i} />
          <Pagination
            mode="number"
            total={pages}
            current={i}
            style={{ marginTop: '1rem' }}
          />
        </div>
      );
    }
    return res;
  };
  return (
    <Document
      file={url}
      onLoadSuccess={e => {
        setPages(e.numPages);
      }}
    >
      {getPages()}
    </Document>
  );
};

export default PDFViewer;
