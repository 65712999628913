import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import config from '@/utils/config';

import WaitList from './WaitList';
import WaitListDetail from './WaitListDetail';
import StudyProtocol from './StudyProtocol';
import Viewer from './StudyProtocol/Viewer';
import Page404 from './Page404';
import FAQs from './FAQs';
import TreatmentDetail from './Treatment/TreatmentDetail';
import Treatment from './Treatment';
import FollowUp from './FollowUp';
import PatientDetail from './FollowUp/PatientDetail';

import TAGuideLines from './TAGuideLines';
import SlotCapability from './SlotCapability';
import Announcement from './Announcement';
import Newsletter from './Newsletter';
import NewsletterViewer from './Newsletter/Viewer';

const Home = () => {
  return (
    <div>
      <Switch>
        <Redirect exact from="/app" to={config.routes.waitlist} />
        <Route exact path={config.routes.waitlist} component={WaitList} />
        <Route exact path={config.routes.followUp} component={FollowUp} />
        <Route
          exact
          path={`${config.routes.followUp}/:id`}
          component={PatientDetail}
        />
        <Route
          exact
          path={`${config.routes.waitlist}/:id`}
          component={WaitListDetail}
        />
        <Route exact path={config.routes.treatment} component={Treatment} />
        <Route
          exact
          path={`${config.routes.treatment}/:id`}
          component={TreatmentDetail}
        />

        <Route
          exact
          path={config.routes.studyprotocol}
          component={StudyProtocol}
        />
        <Route
          exact
          path={`${config.routes.studyprotocol}/:id`}
          component={Viewer}
        />
        <Route exact path={config.routes.faqs} component={FAQs} />
        <Route
          exact
          path={config.routes.taguideline}
          component={TAGuideLines}
        />
        <Route
          exact
          path={config.routes.slotCapability}
          component={SlotCapability}
        />
        <Route
          exact
          path={config.routes.announcement}
          component={Announcement}
        />
        <Route exact path={config.routes.newsletter} component={Newsletter} />
        <Route
          exact
          path={`${config.routes.newsletter}/:id`}
          component={NewsletterViewer}
        />
        <Route component={Page404} />
      </Switch>
    </div>
  );
};

export default connect()(Home);
