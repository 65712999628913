/**
 * Routes
 */
const routes = {
  register: '/register',
  app: '/app',
  waitlist: '/app/waitlist',
  treatment: '/app/treatment',
  studyprotocol: '/app/studyprotocol',
  followUp: '/app/followup',
  faqs: '/app/faq',
  taguideline: '/app/taguideline',
  slotCapability: '/app/slotcapability',
  announcement: '/app/announcement',
  newsletter: '/app/newsletter'
};

/**
 * APIS
 */
const api = {
  sites: '/sites',
  auth: '/connect/wechat/callback',
  wechat: '/wechats',
  patients: '/patients',
  protocols: '/studyprotocols',
  faqs: '/configs/cartifan1',
  announcements: '/announcements',
  newsletters: '/newsletters'
};

/**
 *  CONST
 */
const role = {
  scientist: 'scientist',
  jnj: 'jnj'
};

export default { routes, api, role };
