import React from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { FETCH_WAIT_LIST, FETCH_WAIT_LIST_OTHER, FETCH_SITE } from '@/actions';
import CardList from '@components/CardList';
import { push } from 'connected-react-router';
import map from './map';
import { Toast, NavBar, Icon } from 'antd-mobile';
import FilterTab from '@/components/FilterTab';
import mapSort from './mapSort';
import mapFilter from './mapFilter';
import LoadMore from '@/components/LoadMore';
import mapFilterForStudyCenter from './mapFilterForStudyCenter';

const WaitList = ({ dispatch, waitList, count, sites }) => {
  const user = JSON.parse(sessionStorage.getItem('user'));

  const [filterBarStyle, setFilterBarStyle] = React.useState(
    styles['bar-absolute']
  );
  const filter = React.useRef(null);
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (!waitList) {
      new Promise((resolve, reject) => {
        let filter = JSON.parse(sessionStorage.getItem('filter'));
        const sort = JSON.parse(sessionStorage.getItem('sort'));
        if (!filter) {
          sessionStorage.removeItem('filter');
          filter = {};
        }
        const filterkeys = Object.keys(filter);
        if (filterkeys.length > 0) {
          filterkeys.forEach(key => {
            if (filter[key] == '') {
              delete filter[key];
            }
          });
        } else {
          filter = JSON.parse(sessionStorage.getItem('filter'));
        }

        let s;
        mapSort.forEach(e => {
          if (e.value === sort) {
            s = e.key;
          }
        });
        dispatch({
          type: FETCH_WAIT_LIST,
          payload: { values: { filter, s }, resolve, reject }
        });
      }).then(() => {
        Toast.hide();
        if (!sites) {
          new Promise((resolve, reject) => {
            dispatch({
              type: FETCH_SITE,
              payload: { resolve, reject }
            });
          });
        }
      });
    }
    document.addEventListener('scroll', trackScrolling);

    return () => {
      document.removeEventListener('scroll', trackScrolling);
    };
  }, [dispatch, waitList]);

  const trackScrolling = e => {
    if (filter.current && filter.current.getBoundingClientRect().top < 0) {
      if (filterBarStyle === styles['bar-fixed']) {
        return;
      }
      setFilterBarStyle(styles['bar-fixed']);
    } else if (
      ref &&
      ref.current &&
      ref.current.getBoundingClientRect().top > 50
    ) {
      setFilterBarStyle(styles['bar-absolute']);
    }
  };

  if (!waitList || !user) {
    Toast.loading('Loading...', 0, 1);
    return (
      <div>
        <NavBar mode="dark">Waitlist</NavBar>
      </div>
    );
  }
  return (
    <div>
      <NavBar mode="dark">Waitlist</NavBar>
      <div className={styles.body}>
        <FilterTab
          open={() => {
            if (
              filter.current &&
              filter.current.getBoundingClientRect().top > 0
            ) {
              window.scrollTo(0, 45);
            }
          }}
          style={filterBarStyle}
          fref={filter}
          sortMap={mapSort}
          filterMap={
            user._doc.type === 'study center'
              ? mapFilterForStudyCenter
              : mapFilter(sites)
          }
          handleSubmit={(sort, filter) => {
            if (filter !== undefined) {
              const filterkeys = Object.keys(filter);
              if (filterkeys.length > 0) {
                filterkeys.forEach(key => {
                  if (filter[key] == '') {
                    delete filter[key];
                  }
                });
              }
            } else if (filter === null) {
              sessionStorage.removeItem('filter');
            } else {
              filter = JSON.parse(sessionStorage.getItem('filter'));
            }

            let s;
            mapSort.forEach(e => {
              if (e.value === sort) {
                s = e.key;
              }
            });
            sessionStorage.setItem('filter', JSON.stringify(filter));
            sessionStorage.setItem('sort', JSON.stringify(sort));
            new Promise((resolve, reject) => {
              dispatch({
                type: FETCH_WAIT_LIST,
                payload: { values: { filter, s }, resolve, reject }
              });
            });
          }}
        />

        {count === 0 ? (
          <LoadMore title={'No data'} />
        ) : (
          <React.Fragment>
            <div style={{ margin: '0.8rem 1rem', color: '#777' }} ref={ref}>
              About {count} results
            </div>
            <CardList
              data={waitList}
              map={map}
              handleClick={e => {
                dispatch(push(`/app/waitlist/${e.id}`));
              }}
              hasEnd={waitList.length === count}
              handleReachBottom={() => {
                let filter = JSON.parse(sessionStorage.getItem('filter'));
                const sort = JSON.parse(sessionStorage.getItem('sort'));
                if (!filter) {
                  sessionStorage.removeItem('filter');
                  filter = {};
                }
                const filterkeys = Object.keys(filter);
                if (filterkeys.length > 0) {
                  filterkeys.forEach(key => {
                    if (filter[key] == '') {
                      delete filter[key];
                    }
                  });
                } else {
                  filter = JSON.parse(sessionStorage.getItem('filter'));
                }

                let s;
                mapSort.forEach(e => {
                  if (e.value === sort) {
                    s = e.key;
                  }
                });

                dispatch({
                  type: FETCH_WAIT_LIST_OTHER,
                  payload: { values: { filter, s } }
                });
              }}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default connect(state => ({
  waitList: state.patient.waitlist,
  count: state.patient.waitlistCount,
  sites: state.user.sites
}))(WaitList);
