import { SAVE } from '@/actions';

const initState = {
  waitlist: null,
  waitlistCount: null,
  treatment: null,
  treatmentCount: null,
  followup: null,
  followupCount: null
};

const patient = (state = initState, action) => {
  switch (action.type) {
    case SAVE:
      return { ...state, [action.payload.key]: action.payload.value };
    default:
      return state;
  }
};

export default patient;
