import React, { Fragment } from 'react';

import { goBack } from 'connected-react-router';
import { NavBar, WingBlank, WhiteSpace, List } from 'antd-mobile';
import { connect } from 'react-redux';
import styles from './detailStyles.module.scss';
import request from '@utils/request';
import config from '@utils/config';
import dateMap from './dateMap';
import common from '@utils/common';

const { Item } = List;
const { Brief } = Item;
const PatientDetail = ({ dispatch, followup }) => {
  const [patient, setPatient] = React.useState(null);

  React.useEffect(() => {
    const ids = window.location.href.split('/');
    const id = ids[ids.length - 1];
    if (!followup) {
      request.get({ url: `${config.api.patients}/${id}` }).then(res => {
        setPatient(res);
      });
    } else {
      setPatient(followup.find(element => element.id === id));
    }
  }, [dispatch]);

  const getList = () => {
    const res = [];
    if (patient.statusFollowUp === 'Prior to PD') {
      let flag = true;

      dateMap(patient.statusFollowUp).forEach(e => {
        res.push(
          <Item
            style={
              new Date(
                common.addDate(patient.dateCARTInfusion, e - 1)
              ).valueOf() < new Date().valueOf()
                ? { background: '#eee' }
                : {}
            }
            key={e}
            extra={common.addDate(patient.dateCARTInfusion, e - 1)}
          >
            {`Day ${e}`}
            {new Date(
              common.addDate(patient.dateCARTInfusion, e - 1)
            ).valueOf() > new Date().valueOf() && flag ? (
              <Brief>next visit day</Brief>
            ) : (
              ''
            )}
          </Item>
        );
        if (
          new Date(common.addDate(patient.dateCARTInfusion, e - 1)).valueOf() >
          new Date().valueOf()
        ) {
          flag = false;
        }
      });
    } else if (patient.statusFollowUp === 'Post PD') {
      let flag = true;

      dateMap(patient.statusFollowUp).forEach((e, i) => {
        if (i === 0) {
          res.push(
            <Item
              style={
                new Date(
                  common.addDate(patient.datePDFollowUp, e - 1)
                ).valueOf() < new Date().valueOf()
                  ? { background: '#eee' }
                  : {}
              }
              key={e}
              extra={common.addDate(patient.datePDFollowUp, e - 1)}
            >
              PD Date
              {new Date(
                common.addDate(patient.datePDFollowUp, e - 1)
              ).valueOf() > new Date().valueOf() && flag ? (
                <Brief>next visit day</Brief>
              ) : (
                ''
              )}
            </Item>
          );
        } else {
          res.push(
            <Item
              style={
                new Date(
                  common.addDate(patient.datePDFollowUp, e - 1)
                ).valueOf() < new Date().valueOf()
                  ? { background: '#eee' }
                  : {}
              }
              key={e}
              extra={common.addDate(patient.datePDFollowUp, e - 1)}
            >
              {`Follow up visit-${i}`}
              {new Date(
                common.addDate(patient.datePDFollowUp, e - 1)
              ).valueOf() > new Date().valueOf() && flag ? (
                <Brief>next visit day</Brief>
              ) : (
                ''
              )}
            </Item>
          );
        }

        if (
          new Date(common.addDate(patient.dateCARTInfusion, e - 1)).valueOf() >
          new Date().valueOf()
        ) {
          flag = false;
        }
      });
    }

    return (
      <List renderHeader={() => 'Visit Dates'} style={{ flexBasis: 'initial' }}>
        {res}
      </List>
    );
  };

  if (!patient) {
    return <div className={styles.body}>no patient found</div>;
  }

  if (patient.statusFollowUp === 'Safety follow-up') {
    return (
      <div className={styles.body}>
        <NavBar
          leftContent="Back"
          onLeftClick={() => dispatch(goBack())}
          mode="dark"
        >
          Detail
        </NavBar>
        <WhiteSpace size="lg" />
        <WingBlank>
          Please follow up all the AEs until recovery, study completion or 6m
          post treatment (whichever is earlier)
        </WingBlank>
      </div>
    );
  } else if (patient.statusFollowUp === 'Specific follow-up no required') {
    return (
      <div className={styles.body}>
        <NavBar
          leftContent="Back"
          onLeftClick={() => dispatch(goBack())}
          mode="dark"
        >
          Detail
        </NavBar>
        <WhiteSpace size="lg" />
        <WingBlank>
          The patient's status is specific follow-up no required.
        </WingBlank>
      </div>
    );
  }

  return (
    <div className={styles.body}>
      <NavBar
        leftContent="Back"
        onLeftClick={() => dispatch(goBack())}
        mode="dark"
      >
        Detail
      </NavBar>
      <WhiteSpace size="lg" />
      {getList()}
    </div>
  );
};

export default connect(state => ({
  followup: state.patient.followup
}))(PatientDetail);
