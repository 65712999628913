/*
 * @defination: define post, put, patch, delete, get, fetch http requests
 */
import service from './axios';
// import mock from '../../mock/index';

/**
 * GET method to get list data or an item by id
 * @param {string} url
 * @param {object} params
 * @param {string} id
 */
function get({ url, params = {}, id = '' }) {
  if (id) {
    id = `/${id}`;
  }
  url = `${url}${id}`;
  return new Promise((resolve, reject) => {
    service
      .get(url, {
        params: params
      })
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
}

/**
 * PATCH method to update an new item
 * @param {string} url
 * @param {object} data
 */
function patch({ url, data = {} }) {
  return new Promise((resolve, reject) => {
    service.patch(url, data).then(
      response => {
        resolve(response);
      },
      err => {
        reject(err);
      }
    );
  });
}

/**
 * POST method to post an new item
 * @  if want to change content type write {headers: {'Content-Type': 'application/x-www-form-urlencoded'}} in options
 * @param {string} url
 * @param {object} data
 */
function post({ url, data = {}, options = {} }) {
  return new Promise((resolve, reject) => {
    service.post(url, data, options).then(
      response => {
        resolve(response);
      },
      err => {
        reject(err);
      }
    );
  });
}

/**
 * PUT method to update data of an item
 * @param {string} url
 * @param {string} id
 * @param {object} data
 */
function put({ url, id = '', data = {} }) {
  if (id) {
    id = `/${id}`;
  }
  url = `${url}${id}`;
  //TODO: add id to url according to backend defination
  return new Promise((resolve, reject) => {
    service.put(url, data).then(
      response => {
        resolve(response);
      },
      err => {
        reject(err);
      }
    );
  });
}

/**
 * Delete method to delete all the data or an item by id
 * @param {*} url
 * @param {*} id
 */
function del({ url, id = null }) {
  if (id) url = `${url}/${id}`;
  //TODO: add id to url according to backend defination
  return new Promise((resolve, reject) => {
    service.delete(url).then(
      response => {
        resolve(response);
      },
      err => {
        reject(err);
      }
    );
  });
}

/**
 * Cancel all the request
 */
function cancel() {
  service.cancel();
}

export default {
  get,
  put,
  post,
  patch,
  del,
  cancel
};
