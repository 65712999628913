import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { FETCH_NEWSLETTER } from '@/actions';
import { List, Toast, NavBar, Tag, Badge } from 'antd-mobile';
import common from '@utils/common';
import { push } from 'connected-react-router';

const Item = List.Item;

const Newsletter = ({ dispatch, newsletters }) => {
  React.useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch({
        type: FETCH_NEWSLETTER,
        payload: { resolve, reject }
      });
    }).then(() => {
      Toast.hide();
    });
  }, [dispatch]);
  if (!newsletters) {
    Toast.loading('Loading...', 0, 1);
    return null;
  }
  return (
    <Fragment>
      <NavBar mode="dark">Newsletter</NavBar>
      <List
        renderHeader={() => (
          <div>
            <span>{`${newsletters.length} files`}</span>
          </div>
        )}
      >
        {newsletters.map((element, index) => {
          return (
            <Item
              key={element.id}
              multipleLine
              extra={
                <img
                  style={{
                    height: '100px',
                    width: '100px',
                    objectFit: 'cover'
                  }}
                  src={element.thumb.url}
                  alt=""
                />
              }
            >
              {element.title}
              <Item.Brief>{common.getDate(element.createdAt)}</Item.Brief>
              <Item.Brief style={{ whiteSpace: 'normal' }}>
                {element.content}
              </Item.Brief>
              <Item.Brief>
                <Badge
                  text="View File"
                  hot
                  style={{
                    backgroundColor: '#108ee9',
                    borderRadius: 2
                  }}
                  onClick={() => {
                    dispatch(push(`/app/newsletter/${element.id}`));
                  }}
                />
              </Item.Brief>
            </Item>
          );
        })}
      </List>
    </Fragment>
  );
};

export default connect(state => ({
  newsletters: state.user.newsletters
}))(Newsletter);
