const TOKEN_NAME = 'jwt';

export const setToken = token => {
  return sessionStorage.setItem(TOKEN_NAME, token);
};

export const getToken = () => {
  return sessionStorage.getItem(TOKEN_NAME);
};

export const delToken = () => {
  return sessionStorage.removeItem(TOKEN_NAME);
};
