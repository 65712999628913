import React from 'react';
import { Steps } from 'antd-mobile';

import get from 'lodash.get';
import common from '@/utils/common';

const { Step } = Steps;

const completeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 42 42"
    className="am-icon am-icon-md"
  >
    <g fillRule="evenodd" stroke="transparent" strokeWidth="4">
      <path
        fill="#108ee9"
        d="M21 0C9.402 0 0 9.402 0 21c0 11.6 9.402 21 21 21s21-9.4 21-21C42 9.402 32.598 0 21 0z"
      />

      <path fill="#fff" d="M10.2 21.2L8 23.3l10 9.9L35.2 14 33 12 17.8 29.8z" />
    </g>
  </svg>
);
const pendingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 42 42"
    className="am-icon am-icon-md"
  >
    <g fillRule="evenodd" stroke="transparent" strokeWidth="4">
      <path d="M21 0C9.402 0 0 9.402 0 21c0 11.6 9.402 21 21 21s21-9.4 21-21C42 9.402 32.598 0 21 0z" />
    </g>
  </svg>
);

const getSteps = (map, data) => {
  const res = [];
  map.forEach(element => {
    if (element.showIndex && !get(data, element.showIndex)) {
      return;
    }
    let icon = pendingIcon();
    let status = 'wait';
    const source = get(data, element.index);
    const sourceDone = get(data, element.doneIndex);
    if (sourceDone) {
      icon = completeIcon();
      status = 'finish';
    }
    if (element.hasIcon) {
      icon = element.hasIcon;
    }
    res.push(
      <Step
        key={element.index}
        status={status}
        title={element.title}
        icon={icon}
        description={
          source
            ? element.keepDate
              ? source
              : common.getDate(new Date(source))
            : ''
        }
      />
    );
  });
  return res;
};

const TreatmentSteps = ({ map, data }) => {
  if (!map) {
    return null;
  }
  return <Steps>{getSteps(map, data)}</Steps>;
};

export default TreatmentSteps;
