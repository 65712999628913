import React, { Fragment } from 'react';

import { goBack } from 'connected-react-router';
import { Steps, NavBar, WingBlank, WhiteSpace } from 'antd-mobile';
import { connect } from 'react-redux';
import styles from './detailStyles.module.scss';
import TreatmentSteps from '@components/TreatmentSteps';
import mapTreatmentDetail from './mapTreatmentDetail';
import request from '@utils/request';
import config from '@utils/config';

const { Step } = Steps;

const TreatmentDetail = ({ dispatch, treatment }) => {
  const [patient, setPatient] = React.useState(null);

  React.useEffect(() => {
    const ids = window.location.href.split('/');
    const id = ids[ids.length - 1];
    if (!treatment) {
      request.get({ url: `${config.api.patients}/${id}` }).then(res => {
        setPatient(res);
      });
    } else {
      setPatient(treatment.find(element => element.id === id));
    }
  }, [dispatch]);

  if (!patient) {
    return <div>no patient found</div>;
  }
  return (
    <div className={styles.body}>
      <NavBar
        leftContent="Back"
        onLeftClick={() => dispatch(goBack())}
        mode="dark"
      >
        Detail
      </NavBar>
      <WhiteSpace size="lg" />
      <WingBlank>
        <TreatmentSteps map={mapTreatmentDetail} data={patient} />
      </WingBlank>
    </div>
  );
};

export default connect(state => ({
  treatment: state.patient.treatment
}))(TreatmentDetail);
