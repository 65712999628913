import React from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import CardList from '@components/CardList';
import { push } from 'connected-react-router';
import map from './map';
import { Toast, NavBar, Icon } from 'antd-mobile';
import LoadMore from '@/components/LoadMore';
import { FETCH_FOLLOWUP, FETCH_FOLLOWUP_OTHER } from '@/actions';

const PatientList = ({ dispatch, followup, count, sites }) => {
  const user = JSON.parse(sessionStorage.getItem('user'));

  const [filterBarStyle, setFilterBarStyle] = React.useState(
    styles['bar-absolute']
  );
  const filter = React.useRef(null);
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (!followup) {
      new Promise((resolve, reject) => {
        dispatch({
          type: FETCH_FOLLOWUP,
          payload: { resolve, reject }
        });
      }).then(() => {
        Toast.hide();
      });
    }
    document.addEventListener('scroll', trackScrolling);

    return () => {
      document.removeEventListener('scroll', trackScrolling);
    };
  }, [dispatch, followup]);

  const trackScrolling = e => {
    if (filter.current && filter.current.getBoundingClientRect().top < 0) {
      if (filterBarStyle === styles['bar-fixed']) {
        return;
      }
      setFilterBarStyle(styles['bar-fixed']);
    } else if (
      ref &&
      ref.current &&
      ref.current.getBoundingClientRect().top > 50
    ) {
      setFilterBarStyle(styles['bar-absolute']);
    }
  };

  if (!followup || !user) {
    Toast.loading('Loading...', 0, 1);
    return <div />;
  }
  return (
    <div>
      <div className={styles.body}>
        {count === 0 ? (
          <LoadMore title={'No data'} />
        ) : (
          <React.Fragment>
            <div style={{ margin: '0.8rem 1rem', color: '#777' }} ref={ref}>
              About {count} results
            </div>
            <CardList
              data={followup}
              map={map}
              handleClick={e => {
                dispatch(push(`/app/followup/${e.id}`));
              }}
              hasEnd={followup.length === count}
              handleReachBottom={() => {
                dispatch({
                  type: FETCH_FOLLOWUP_OTHER
                });
              }}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default connect(state => ({
  followup: state.patient.followup,
  count: state.patient.followupCount,
  sites: state.user.sites
}))(PatientList);
