import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { FETCH_FAQ } from '@/actions';
import { List, Toast, NavBar } from 'antd-mobile';
import { push } from 'connected-react-router';

const Item = List.Item;

const TAGuideLines = ({ dispatch, config }) => {
  React.useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch({
        type: FETCH_FAQ,
        payload: { resolve, reject }
      });
    }).then(() => {
      Toast.hide();
    });
  }, [dispatch]);
  if (!config || !config.TAGuidelines) {
    Toast.loading('Loading...', 0, 1);
    return null;
  }
  return (
    <Fragment>
      <NavBar mode="dark">TA-Guideline</NavBar>
      <List
        renderHeader={() => (
          <div>
            <span>{`${config.TAGuidelines.length} files`}</span>
          </div>
        )}
      >
        {config.TAGuidelines.map((element, index) => {
          return (
            <Item
              arrow="horizontal"
              key={element.title}
              multipleLine
              onClick={() => {
                window.location.href = element.url;
              }}
            >
              {element.title}
            </Item>
          );
        })}
      </List>
    </Fragment>
  );
};

export default connect(state => ({
  config: state.user.configs
}))(TAGuideLines);
