export default sites => {
  if (!sites) return [];
  return [
    {
      key: 'site',
      title: 'Site',
      options: getOptions(sites)
    }
  ];
};

const getOptions = s => {
  const o = [];
  s.forEach(element => {
    o.push({ key: element.id, label: `${element.siteID}-${element.siteName}` });
  });
  return o;
};
