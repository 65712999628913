import React from 'react';
import styles from './index.module.scss';

const SINGLE_TYPE_SELECT = 'single';
const MULTI_TYPE_SELECT = 'multiple';

const BtnGroup = ({ options, handleClick, type, params, selectedArray }) => {
  const isSelected = key => {
    return selectedArray.indexOf(key) > -1;
  };

  const addRemoveSelect = key => {
    if (key === '') {
      return handleClick({ key: params, value: [''] });
    }
    let newSelected = JSON.parse(JSON.stringify(selectedArray));
    if (type === SINGLE_TYPE_SELECT) {
      newSelected = [key];
    } else if (type === MULTI_TYPE_SELECT) {
      if (isSelected(key)) {
        newSelected = newSelected.filter(s => s !== key);
      } else {
        newSelected.push(key);
      }
    } else {
      console.log('OPTIONS TYPE ERROR');
    }
    newSelected = newSelected.filter(ele => ele !== '');
    handleClick({ key: params, value: newSelected });
  };

  let all = [{ key: '', label: 'All' }];
  options = all.concat(options);
  return options.map(opt => {
    if (opt.label.length > 35) {
      return (
        <div
          className={
            isSelected(opt.key)
              ? styles['filter-selected-long']
              : styles['option-long']
          }
          key={opt.key}
          onClick={() => addRemoveSelect(opt.key)}
        >
          {/* <span style={{ height: '100%' }}> </span> */}
          {/* <sapn style={{ verticalAlign: 'middle' }}>{opt.label}</sapn> */}
          {opt.label}
        </div>
      );
    }
    return (
      <div
        className={
          isSelected(opt.key) ? styles['filter-selected'] : styles.option
        }
        key={opt.key}
        onClick={() => addRemoveSelect(opt.key)}
      >
        {/* <span style={{ height: '100%' }}> </span>
        <sapn style={{ verticalAlign: 'middle' }}>{opt.label}</sapn> */}
        {opt.label}
      </div>
    );
  });
};

export default BtnGroup;
