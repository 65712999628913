import React from 'react';
import { Card, Icon } from 'antd-mobile';
import get from 'lodash.get';

import styles from './index.module.scss';
import LoadMore from '@components/LoadMore';

const CardItem = ({ title, extra, extraTitle, data, map }) => {
  const getBody = () => {
    const body = [];
    map.forEach((element, index) => {
      const content = get(data, element.index);
      if (content || element.require) {
        body.push(
          <div key={index} className={styles.item}>
            <div className={styles['item-title']}>{element.title}</div>
            <div className={styles['item-content']}>
              {element.render ? element.render(content, data) : content}
            </div>
          </div>
        );
      }
    });
    return body;
  };
  return (
    <Card full>
      <Card.Header
        title={title}
        extra={
          <span>
            {extraTitle}
            {get(data, extra)}
          </span>
        }
      />
      <Card.Body>{getBody()}</Card.Body>
    </Card>
  );
};

const CardList = ({ data, map, handleClick, handleReachBottom, hasEnd }) => {
  React.useEffect(() => {
    document.addEventListener('scroll', trackScrolling);
    return () => {
      document.removeEventListener('scroll', trackScrolling);
    };
  });
  const ref = React.useRef();
  const trackScrolling = e => {
    if (
      ref.current &&
      ref.current.getBoundingClientRect().top <
        document.getElementById('root').clientHeight
    ) {
      document.removeEventListener('scroll', trackScrolling);
      handleReachBottom();
    }
  };
  let titleIndex, extraIndex, extraTitle, iconIndex;
  map.forEach(element => {
    if (element.isTitle) {
      titleIndex = element.index;
    }
    if (element.isExtra) {
      extraIndex = element.index;
      extraTitle = element.title;
    }
    if (element.isIcon) {
      iconIndex = element.index;
    }
  });
  map = map.filter(
    element => !element.isTitle && !element.isExtra && !element.isIcon
  );
  const list = data.map(element => {
    let title;
    if (typeof titleIndex === 'object') {
      for (let i = 0; i < titleIndex.length; i++) {
        if (element[titleIndex[i]]) {
          title = element[titleIndex[i]];
          break;
        }
      }
    } else {
      title = element[titleIndex];
    }
    return (
      <div
        key={element.id}
        className={styles.card}
        onClick={() => {
          handleClick(element);
        }}
      >
        <CardItem
          data={element}
          key={element.id}
          title={
            <div
              className={styles['card-title']}
              style={element[iconIndex] ? {} : { marginLeft: 'unset' }}
            >
              <span
                className={styles['card-icon']}
                style={element[iconIndex] ? {} : { display: 'none' }}
              >
                {element[iconIndex] || '1'}
              </span>
              {title}
            </div>
          }
          extra={extraIndex}
          extraTitle={extraTitle}
          map={map}
        />
      </div>
    );
  });

  return (
    <React.Fragment>
      {list}
      {!hasEnd ? (
        <div
          ref={ref}
          style={{
            textAlign: 'center',
            padding: '0 0 0.8rem'
          }}
        >
          <Icon
            type="loading"
            size="xs"
            style={{
              verticalAlign: 'middle',
              marginRight: '0.3rem'
            }}
          />
          <span
            style={{
              fontSize: '1rem',
              opacity: '0.8',
              verticalAlign: 'middle'
            }}
          >
            loading
          </span>
        </div>
      ) : (
        <LoadMore title={'End'} />
      )}
    </React.Fragment>
  );
};

export default CardList;
