export default [
  {
    value: 'Manufacture Day',
    label: 'Manufacture Day',
    key: 'slot.manufactureDay0:ASC'
  },
  {
    value: 'Descending Manufacture Day',
    label: 'Descending Manufacture Day',
    key: 'slot.manufactureDay0:DESC'
  }
];
