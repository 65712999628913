import { takeEvery, put, delay, call, select } from 'redux-saga/effects';
import {
  TEST,
  LOGIN,
  SAVE,
  REGISTER,
  FETCH_SITE,
  FETCH_WAIT_LIST,
  FETCH_WAIT_LIST_OTHER,
  FETCH_PROTOCOL,
  VIEW_PROTOCOL,
  FETCH_FAQ,
  FETCH_TREATMENT,
  FETCH_TREATMENT_OTHER,
  FETCH_FOLLOWUP,
  FETCH_FOLLOWUP_OTHER,
  FETCH_ANNOUNCEMENT,
  FETCH_NEWSLETTER
} from '@/actions';
import request from '@utils/request';
import config from '@utils/config';

// need to handle promise back
function* helloWorld({ type, payload: { values, reject, resolve } }) {
  try {
    yield delay(1000);
    resolve();
  } catch (err) {
    reject();
  }
}

function* login({ type, payload: { value } }) {
  try {
    const data = yield call(request.get, {
      url: `${config.api.auth}`,
      params: value
    });
    // if response contains openid which means the user need to register
    if (data && data.openid) {
      // save user info
      yield put({
        type: SAVE,
        payload: { key: 'user', value: data }
      });
      sessionStorage.setItem('user', JSON.stringify(data));
      window.location.href = '/register';
    } else if (data && data.jwt) {
      sessionStorage.setItem('jwt', data.jwt);
      sessionStorage.setItem('user', JSON.stringify(data.user));
      window.location.href = sessionStorage.getItem('des') || '/waitlist';
    }
  } catch (err) {}
}

function* register({ type, payload: { values, resolve, reject } }) {
  sessionStorage.removeItem('user');
  try {
    const data = yield call(request.put, {
      url: `${config.api.wechat}/${values.id}/register`,
      data: values
    });
    yield put({
      type: SAVE,
      payload: {
        key: 'user',
        value: data
      }
    });
    resolve();
  } catch (err) {
    reject(err);
  }
}
function* fetchSite({ type, payload: { values, resolve, reject } }) {
  try {
    const data = yield call(request.get, {
      url: `${config.api.sites}`
    });
    if (!data) {
      reject();
    }
    yield put({
      type: SAVE,
      payload: { key: 'sites', value: data }
    });
    resolve(data);
  } catch (err) {
    reject(err);
  }
}

function* fetchWaitList({ type, payload: { values, resolve, reject } }) {
  const user = JSON.parse(sessionStorage.getItem('user'));
  let site = {};
  if (user && user._doc && user._doc.type === 'study center') {
    site = { site: user._doc.site.id };
  }
  let filter = {};
  if (values && values.filter) {
    filter = values.filter;
  }
  let sort = { _sort: 'ranking' };

  if (values && values.s) {
    sort = { _sort: values.s };
  }
  try {
    const data = yield call(request.get, {
      url: `${config.api.patients}`,
      params: {
        _limit: 10,
        status: 'Waitlist',
        ...sort,
        ...filter,
        ...site
      }
    });
    const count = yield call(request.get, {
      url: `${config.api.patients}/count`,
      params: {
        status: 'Waitlist',
        ...filter,
        ...site
      }
    });
    if (!data) {
      reject();
    }
    yield put({
      type: SAVE,
      payload: { key: 'waitlist', value: data }
    });
    yield put({
      type: SAVE,
      payload: { key: 'waitlistCount', value: count }
    });
    resolve(data);
  } catch (err) {
    reject(err);
  }
}

function* fetchWaitListOther({ type, payload: { values } }) {
  const user = JSON.parse(sessionStorage.getItem('user'));
  let site = {};
  if (user && user._doc && user._doc.type === 'study center') {
    site = { site: user._doc.site.id };
  }
  let filter = {};
  if (values && values.filter) {
    filter = values.filter;
  }
  let sort = { _sort: 'ranking' };

  if (values && values.s) {
    sort = { _sort: values.s };
  }
  const list = yield select(state => state.patient.waitlist);
  const data = yield call(request.get, {
    url: `${config.api.patients}`,
    params: {
      _limit: 10,
      status: 'Waitlist',
      _start: list.length,
      ...sort,
      ...filter,
      ...site
    }
  });
  yield put({
    type: SAVE,
    payload: { key: 'waitlist', value: list.concat(data) }
  });
}

function* fetchTreatment({ type, payload: { values, resolve, reject } }) {
  const user = JSON.parse(sessionStorage.getItem('user'));
  let site = {};
  if (user && user._doc && user._doc.type === 'study center') {
    site = { site: user._doc.site.id };
  }
  let filter = {};
  if (values && values.filter) {
    filter = values.filter;
  }
  let sort = { _sort: 'slot.manufactureDay0:ASC' };

  if (values && values.s) {
    sort = { _sort: values.s };
  }
  try {
    const data = yield call(request.get, {
      url: `${config.api.patients}`,
      params: {
        _limit: 10,
        status: 'Treatment',
        ...sort,
        ...filter,
        ...site
      }
    });
    const count = yield call(request.get, {
      url: `${config.api.patients}/count`,
      params: {
        status: 'Treatment',
        ...filter,
        ...site
      }
    });
    if (!data) {
      reject();
    }
    yield put({
      type: SAVE,
      payload: { key: 'treatment', value: data }
    });
    yield put({
      type: SAVE,
      payload: { key: 'treatmentCount', value: count }
    });
    resolve(data);
  } catch (err) {
    reject(err);
  }
}

function* fetchTreatmentOther({ type, payload: { values } }) {
  const user = JSON.parse(sessionStorage.getItem('user'));
  let site = {};
  if (user && user._doc && user._doc.type === 'study center') {
    site = { site: user._doc.site.id };
  }
  let filter = {};
  if (values && values.filter) {
    filter = values.filter;
  }
  let sort = { _sort: 'slot.manufactureDay0:ASC' };

  if (values && values.s) {
    sort = { _sort: 'slot.manufactureDay0:ASC' };
  }
  const list = yield select(state => state.patient.treatment);
  const data = yield call(request.get, {
    url: `${config.api.patients}`,
    params: {
      _limit: 10,
      status: 'Treatment',
      _start: list.length,
      ...sort,
      ...filter,
      ...site
    }
  });
  yield put({
    type: SAVE,
    payload: { key: 'treatment', value: list.concat(data) }
  });
}

function* fetchFollowUp({ type, payload: { values, resolve, reject } }) {
  const user = JSON.parse(sessionStorage.getItem('user'));
  let site = {};
  if (user && user._doc && user._doc.type === 'study center') {
    site = { site: user._doc.site.id };
  }

  try {
    const data = yield call(request.get, {
      url: `${config.api.patients}`,
      params: {
        _limit: 10,
        status: 'Follow-up',
        ...site
      }
    });
    const count = yield call(request.get, {
      url: `${config.api.patients}/count`,
      params: {
        status: 'Follow-up',
        ...site
      }
    });
    if (!data) {
      reject();
    }
    yield put({
      type: SAVE,
      payload: { key: 'followup', value: data }
    });
    yield put({
      type: SAVE,
      payload: { key: 'followupCount', value: count }
    });
    resolve(data);
  } catch (err) {
    reject(err);
  }
}

function* fetchFollowUpOther({ type, payload: { values } }) {
  const user = JSON.parse(sessionStorage.getItem('user'));
  let site = {};
  if (user && user._doc && user._doc.type === 'study center') {
    site = { site: user._doc.site.id };
  }

  const list = yield select(state => state.patient.treatment);
  const data = yield call(request.get, {
    url: `${config.api.patients}`,
    params: {
      _limit: 10,
      status: 'Follow-up',
      _start: list.length,
      ...site
    }
  });
  yield put({
    type: SAVE,
    payload: { key: 'followup', value: list.concat(data) }
  });
}

function* fetchProtocol({ type, payload: { values, resolve, reject } }) {
  try {
    const data = yield call(request.get, {
      url: `${config.api.protocols}`
    });
    if (!data) {
      reject();
    }
    yield put({
      type: SAVE,
      payload: { key: 'protocols', value: data }
    });
    resolve(data);
  } catch (err) {
    reject(err);
  }
}

function* viewProtocol({ type, payload: { id } }) {
  yield call(request.get, {
    url: `${config.api.protocols}/${id}`
  });
}

function* fetchFaq({ type, payload: { values, resolve, reject } }) {
  try {
    const data = yield call(request.get, {
      url: `${config.api.faqs}`
    });
    if (!data) {
      reject();
    }
    yield put({
      type: SAVE,
      payload: { key: 'configs', value: data }
    });
    resolve(data);
  } catch (err) {
    reject(err);
  }
}

function* fetchAnn({ type, payload: { values, resolve, reject } }) {
  try {
    const data = yield call(request.get, {
      url: `${config.api.announcements}`,
      params: {
        _sort: 'createdAt:DESC'
      }
    });
    if (!data) {
      reject();
    }
    yield put({
      type: SAVE,
      payload: { key: 'announcements', value: data }
    });
    resolve(data);
  } catch (err) {
    reject(err);
  }
}
function* fetchNews({ type, payload: { values, resolve, reject } }) {
  try {
    const data = yield call(request.get, {
      url: `${config.api.newsletters}`,
      params: {
        _sort: 'createdAt:DESC'
      }
    });
    if (!data) {
      reject();
    }
    yield put({
      type: SAVE,
      payload: { key: 'newsletters', value: data }
    });
    resolve(data);
  } catch (err) {
    reject(err);
  }
}
// 同时使用它们
export default function* saga() {
  yield takeEvery(TEST, helloWorld);
  yield takeEvery(REGISTER, register);
  yield takeEvery(LOGIN, login);
  yield takeEvery(FETCH_SITE, fetchSite);
  yield takeEvery(FETCH_WAIT_LIST, fetchWaitList);
  yield takeEvery(FETCH_WAIT_LIST_OTHER, fetchWaitListOther);
  yield takeEvery(FETCH_TREATMENT, fetchTreatment);
  yield takeEvery(FETCH_TREATMENT_OTHER, fetchTreatmentOther);
  yield takeEvery(FETCH_FOLLOWUP, fetchFollowUp);
  yield takeEvery(FETCH_FOLLOWUP_OTHER, fetchFollowUpOther);
  yield takeEvery(FETCH_PROTOCOL, fetchProtocol);
  yield takeEvery(VIEW_PROTOCOL, viewProtocol);
  yield takeEvery(FETCH_FAQ, fetchFaq);
  yield takeEvery(FETCH_ANNOUNCEMENT, fetchAnn);
  yield takeEvery(FETCH_NEWSLETTER, fetchNews);
}
