import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { FETCH_ANNOUNCEMENT } from '@/actions';
import { List, Toast, NavBar } from 'antd-mobile';
import common from '@utils/common';

const Item = List.Item;

const Announcement = ({ dispatch, announcements }) => {
  React.useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch({
        type: FETCH_ANNOUNCEMENT,
        payload: { resolve, reject }
      });
    }).then(() => {
      Toast.hide();
    });
  }, [dispatch]);
  if (!announcements) {
    Toast.loading('Loading...', 0, 1);
    return null;
  }
  return (
    <Fragment>
      <NavBar mode="dark">Announcement</NavBar>
      <List
        renderHeader={() => (
          <div>
            <span>{`${announcements.length} files`}</span>
          </div>
        )}
      >
        {announcements.map((element, index) => {
          return (
            <Item key={element.id} multipleLine align="top">
              <div style={{ display: 'flex' }}>
                <div style={{ flex: '1' }}>{element.title}</div>
                <div class="am-list-extra">
                  {common.getDate(element.createdAt)}
                </div>
              </div>
              <Item.Brief style={{ whiteSpace: 'normal' }}>
                {element.content}
              </Item.Brief>
            </Item>
          );
        })}
      </List>
    </Fragment>
  );
};

export default connect(state => ({
  announcements: state.user.announcements
}))(Announcement);
