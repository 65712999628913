// action example
export const TEST = 'TEST';

// save state
export const SAVE = 'SAVE';

export const LOGIN = 'LOGIN';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_SITE = 'FETCH_SITE';
export const REGISTER = 'REGISTER';
export const FETCH_WAIT_LIST = 'FETCH_WAIT_LIST';
export const FETCH_WAIT_LIST_OTHER = 'FETCH_WAIT_LIST_OTHER';

export const FETCH_TREATMENT = 'FETCH_TREATMENT';
export const FETCH_TREATMENT_OTHER = 'FETCH_TREATMENT_OTHER';

export const FETCH_FOLLOWUP = 'FETCH_FOLLOWUP';
export const FETCH_FOLLOWUP_OTHER = 'FETCH_FOLLOWUP_OTHER';

export const FETCH_SLOT_TRACKER = 'FETCH_SLOT_TRACKER';

export const FETCH_PROTOCOL = 'FETCH_PROTOCOL';
export const VIEW_PROTOCOL = 'VIEW_PROTOCOL';
export const FETCH_FAQ = 'FETCH_FAQ';
export const FETCH_ANNOUNCEMENT = 'FETCH_ANNOUNCEMENT';
export const FETCH_NEWSLETTER = 'FETCH_NEWSLETTER';
