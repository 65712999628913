/**
 * @defination: customize tab filter and search
 * @props: filter -> array [{key: 'attributes', value: 'display name', options: [types of options to select]}]
 *                               no options will be input
 *                 sort -> array [{key: 'attribtues', value: 'display name'}] default will be the first element
 *                 handleSubmit -> function (sort->[keys], filter-> [keys])
 */
import React from 'react';
import { Drawer } from 'antd-mobile';
import FilterList from '../FilterList';
import SortMenu from '../SortMenu';
import styles from './index.module.scss';

const FilterTab = ({ filterMap, sortMap, handleSubmit, fref, style, open }) => {
  console.log(sortMap);
  const [sort, setSort] = React.useState(
    JSON.parse(sessionStorage.getItem('sort')) || sortMap[0].value
  );
  const [openFilter, setOpenFilter] = React.useState(false);
  const [openSort, setOpenSort] = React.useState(false);

  const sortEl = React.useRef(null);
  React.useEffect(() => {
    const outDivClickHandler = e => {
      if (sortEl.current && !sortEl.current.contains(e.target) && openSort) {
        setOpenSort(false);
      }
    };
    document.addEventListener('click', outDivClickHandler);
    document.addEventListener('touchstart', outDivClickHandler);
    return () => {
      document.removeEventListener('click', outDivClickHandler);
      document.removeEventListener('touchstart', outDivClickHandler);
    };
  }, [openSort]);

  const handleFilter = filter => {
    filterDrawerSwitch();
    handleSubmit(sort, filter);
  };

  const handleSort = sort => {
    setOpenSort(false);
    setSort(sort);
    handleSubmit(sort);
  };

  const filterDrawerSwitch = () => {
    let newS = !openFilter;
    if (newS) {
      open();
      fixedBody();
    } else {
      looseBody();
    }
    return setOpenFilter(newS);
  };

  const sortMenuSwitch = () => {
    let newS = !openSort;
    return setOpenSort(newS);
  };

  const fixedBody = () => {
    const scrollTop =
      document.body.scrollTop || document.documentElement.scrollTop;
    console.log('top', scrollTop);
    document.body.style.cssText +=
      'position:fixed;width: 100%;top:-' + scrollTop + 'px;';
  };

  const looseBody = () => {
    const body = document.body;
    body.style.position = '';
    const top = body.style.top;
    document.body.scrollTop = document.documentElement.scrollTop = -parseInt(
      top
    );
    body.style.top = '';
  };

  return (
    <div ref={fref} className={style}>
      <div className={styles.bar}>
        <div className={styles.font} onClick={sortMenuSwitch}>
          {sort}
        </div>
        {filterMap.length > 0 ? (
          <div className={styles.font} onClick={filterDrawerSwitch}>
            filter
            <div className={styles.icon} />
          </div>
        ) : null}
      </div>
      {openSort ? (
        <div ref={sortEl}>
          <SortMenu
            sortItem={sortMap}
            sort={sort}
            onChange={handleSort}
            className={styles['sort-menu']}
          />
        </div>
      ) : null}
      {filterMap.length > 0 ? (
        <Drawer
          sidebar={
            <FilterList
              isOpen={openFilter}
              filterMap={filterMap}
              className={styles['filter-drawer']}
              handleFilter={e => handleFilter(e)}
            />
          }
          position={'bottom'}
          style={openFilter ? { height: '100vh' } : { top: 'unset' }}
          overlayStyle={
            openFilter ? { position: 'fixed' } : { display: 'none' }
          }
          onOpenChange={filterDrawerSwitch}
          open={openFilter}
          className={styles['filter-drawer']}
        >
          <div />
        </Drawer>
      ) : null}
    </div>
  );
};

export default FilterTab;
