import React, { Fragment } from 'react';
import { NavBar, Tabs } from 'antd-mobile';
import PatientList from './PatientList';
import Calculator from './Calculator';

const tabs = [{ title: 'Patients list' }, { title: 'Schedule Calculator' }];

const FollowUp = props => {
  return (
    <Fragment>
      <NavBar mode="dark">FollowUp</NavBar>
      <Tabs
        tabs={tabs}
        initialPage={0}
        onChange={(tab, index) => {
          console.log('onChange', index, tab);
        }}
        onTabClick={(tab, index) => {
          console.log('onTabClick', index, tab);
        }}
      >
        <PatientList />
        <Calculator />
      </Tabs>
    </Fragment>
  );
};

export default FollowUp;
