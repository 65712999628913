import common from '@utils/common';

export default [
  {
    isTitle: 'Tracking Number',
    index: ['subjectId', 'trackingId']
  },
  {
    isExtra: 'Age',
    index: 'age',
    title: 'Age - '
  },
  {
    title: 'Room',
    index: 'slot.room'
  },
  {
    title: 'Manufacture Day0',
    index: 'slot.manufactureDay0',
    render: date => {
      if (!date) {
        return null;
      }
      return common.getDate(new Date(date));
    }
  }
];
