export default type => {
  if (type === 'Prior to PD') {
    return [
      1,
      28,
      56,
      78,
      100,
      128,
      156,
      184,
      212,
      240,
      268,
      296,
      324,
      352,
      380,
      408,
      436,
      464,
      492,
      520,
      548,
      576,
      604,
      632,
      660,
      688,
      716,
      744,
      772,
      800,
      828,
      856,
      884,
      912,
      940,
      968,
      996,
      1020,
      1048,
      1076,
      1104
    ];
  } else {
    return [1, 128, 256, 384, 512, 640, 768, 896, 1024, 1152];
  }
};
