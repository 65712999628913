import React from 'react';
import { connect } from 'react-redux';
import { List, Picker, DatePicker } from 'antd-mobile';
import dateMap from './dateMap';
import common from '@/utils/common';

const Item = List.Item;
const seasons = [
  [
    {
      label: 'Survival Visit',
      value: '0'
    },
    {
      label: 'Efficacy Visit',
      value: '1'
    }
  ]
];

const Calculator = ({ dispatch, form }) => {
  const [visitType, setVisitType] = React.useState(['0']);
  const [date, setDate] = React.useState(new Date());

  const getList = () => {
    const res = [];
    dateMap(visitType[0] === '0' ? 'Post PD' : 'Prior to PD').forEach(
      (e, i) => {
        if (visitType[0] === '0') {
          if (i === 0) {
            res.push(
              <Item key={e} extra={common.addDate(date, e - 1)}>
                PD Date
              </Item>
            );
          } else {
            res.push(
              <Item
                key={e}
                extra={common.addDate(date, e - 1)}
              >{`Follow up visit-${i}`}</Item>
            );
          }
        } else {
          res.push(
            <Item
              key={e}
              extra={common.addDate(date, e - 1)}
            >{`Day ${e}`}</Item>
          );
        }
      }
    );
    return (
      <List renderHeader={() => 'Visit Dates'} style={{ flexBasis: 'initial' }}>
        {res}
      </List>
    );
  };

  return (
    <div>
      <Picker
        data={seasons}
        cascade={false}
        extra=" "
        value={visitType}
        onChange={v => {
          setVisitType(v);
        }}
        onOk={v => setVisitType(v)}
      >
        <List.Item arrow="horizontal">Visit Type</List.Item>
      </Picker>
      <DatePicker
        mode="date"
        title="Select Date"
        extra="Optional"
        value={date}
        onChange={date => setDate(date)}
      >
        <List.Item arrow="horizontal">
          {visitType[0] === '0' ? 'PD Date' : 'Infusion Date'}
        </List.Item>
      </DatePicker>
      {getList()}
    </div>
  );
};

export default connect(state => ({
  protocols: state.user.protocols
}))(Calculator);
