import { SAVE } from '@/actions';

const initState = {
  user: null,
  sites: null,
  protocols: null,
  configs: null,
  announcements: null,
  newsletters: null
};

const user = (state = initState, action) => {
  switch (action.type) {
    case SAVE:
      return { ...state, [action.payload.key]: action.payload.value };
    default:
      return state;
  }
};

export default user;
