import React from 'react';
import { connect } from 'react-redux';
import { FETCH_FAQ } from '@/actions';
import PDFViewer from '@components/PDFViewer';

const FAQs = ({ dispatch, config }) => {
  React.useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch({
        type: FETCH_FAQ,
        payload: { resolve, reject }
      });
    });
  }, [dispatch]);

  if (!config || !config.slotCapability) {
    return <div>no files</div>;
  }
  console.log(config.slotCapability.url);

  return <PDFViewer url={config.slotCapability.url} />;
};

export default connect(state => ({
  config: state.user.configs
}))(FAQs);
